import { VALIDATE_TOKEN, SET_STORE_TYPE } from '../action-types'

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case VALIDATE_TOKEN:
    case SET_STORE_TYPE:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
export default reducer
