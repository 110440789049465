import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import { connect } from 'react-redux'

import Auth from 'Containers/Auth'
import Config from 'Containers/Config'
import Call from 'Containers/Call'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import FeedbackButton from 'Components/FeedbackButton'
import { PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
  }

  componentDidMount() {
    const { user } = this.props

    this.tangram = new window.Tangram({
      productId: 'f2bc98a5-7fd3-4aa7-8c3c-108f6ea77991', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true,
      },
    })
  }
  render() {
    const { user } = this.props
    return (
      <Layout>
        <Header user={user} />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Config} exact path={'/'} />
              <Route component={Call} path={'/call'} />
              <Route component={Page404} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </Layout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(App)
