import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, InputNumber, Button } from 'antd'
import { equals } from 'ramda'
import { FormItem } from './styled'
class ConfigForm extends Component {
  static propTypes = {
    onSubmitCallback: PropTypes.func.isRequired,
    queues: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.setValues()
  }
  componentDidUpdate(prevProps) {
    const { defaultValues } = this.props
    if (!equals(prevProps.defaultValues, defaultValues)) {
      this.setValues()
    }
  }

  setValues = () => {
    const { form, defaultValues } = this.props
    for (let value in defaultValues) {
      form.setFieldsValue({ [value]: defaultValues[value] })
    }
  }

  handleSubmit = e => {
    const { form, onSubmitCallback } = this.props
    e.preventDefault()
    onSubmitCallback(form)
  }

  render() {
    const { form, queues } = this.props
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem label="Seleziona coda">
          {form.getFieldDecorator('queueId')(
            <Select size="large">
              {queues.map(q => (
                <Select.Option key={q.queueId}>{q.queueName}</Select.Option>
              ))}
            </Select>
          )}
        </FormItem>
        <FormItem label="Seleziona cassa">
          {form.getFieldDecorator('till')(<InputNumber min={1} size="large" />)}
        </FormItem>
        <Form.Item>
          <Button size="large" type="primary" htmlType="submit">
            Salva
          </Button>
        </Form.Item>
      </Form>
    )
  }
}
const WrappedForm = Form.create()(ConfigForm)
export default WrappedForm
