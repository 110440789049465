import styled from 'styled-components'
import { Button, Layout, Typography } from 'antd'
import { colors } from './constants'

export const PaddedContent = styled(Layout.Content)`
  padding: 1rem;
  min-height: calc(100vh - 120px);
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows: auto 1fr;
`
export const StyledButton = styled(Button)`
  opacity: 0.5;
  margin: 1rem;
  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
`
export const PageTitle = styled(Typography.Title)`
  text-transform: uppercase;
  color: ${colors.main} !important;
`
