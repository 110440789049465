import styled from 'styled-components'
import { colors } from 'Common/constants'
export const UserWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`
export const StyledUser = styled.small`
  color: ${colors.main};
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 1rem;
  margin-left: 0.5rem;
`
