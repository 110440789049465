import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import { UserWrapper, StyledUser } from './styled'
const User = props => {
  const surname = props.user.surname && props.user.surname.substring(0, 1)
  return (
    <UserWrapper>
      <Avatar size={'small'} icon="user" />
      <StyledUser>
        {props.user.name} {surname}.
      </StyledUser>
    </UserWrapper>
  )
}
User.propTypes = {
  user: PropTypes.object
}
export default User
