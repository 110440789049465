import styled from 'styled-components'
import { Form } from 'antd'
import { colors } from 'Common/constants'
export const FormItem = styled(Form.Item)`
  & .ant-form-item-label {
    text-align: center;
    & > label {
      color: ${colors.grey4};
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  & .ant-form-item-children > * {
    width: 200px;
  }
`
