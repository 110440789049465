import { SET_QUEUES, SET_CURRENT_CONFIG } from '../action-types'

export const initialState = {
  queues: [],
  till: 1,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_QUEUES:
      return {
        ...state,
        queues: payload,
      }
    case SET_CURRENT_CONFIG:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export default reducer
