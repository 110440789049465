import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import Menu from 'Components/Menu'
import User from 'Components/User'
import { StyledHeader, HeaderBar, AppLogo } from './styled'

const Header = props => {
  const { user } = props
  return (
    <Fragment>
      <HeaderBar />
      <StyledHeader>
        <AppLogo src="/assets/img/LM-logo.png" />
        <Menu />
        {!isEmpty(user) && <User user={user} />}
      </StyledHeader>
    </Fragment>
  )
}

Header.propTypes = {
  user: PropTypes.object,
}
export default Header
