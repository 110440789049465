import { Button } from 'antd'
import styled from 'styled-components'

export const StyledFeedbackButton = styled(Button)`
  background-color: #009fd1;
  border-color: #009fd1;
  color: #fff;
  position: fixed;
  bottom: 16px;
  right: 16px;
  &:active,
  &:focus,
  &:hover {
    background-color: #009fd1;
    border-color: #009fd1;
  }
`
