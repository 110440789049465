import API from './axiosConfig'

export default {
  user: {
    authenticate: token =>
      API.get('/user/details', {
        headers: { Authorization: `Bearer ${token}` },
      }),
  },
  till: {
    newCall: data => API.post('/till/call', data),
  },
}
