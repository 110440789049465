import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setCurrentConfig } from 'Redux/actions/app-actions'

import StoreLabel from 'Components/StoreLabel'
import ConfigForm from 'Components/ConfigForm'

class Config extends Component {
  static propTypes = {
    queues: PropTypes.array,
    queueId: PropTypes.string,
    till: PropTypes.number,
    user: PropTypes.object,
  }

  handleFormSubmit = form => {
    const { history, setCurrentConfig } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        setCurrentConfig(values)
        history.push('/call')
      }
    })
  }

  render() {
    const { user, queues, queueId, till } = this.props
    return (
      <>
        <StoreLabel storeId={user.storeId} storeType={user.storeType} />
        <ConfigForm
          queues={queues}
          defaultValues={{ queueId, till }}
          onSubmitCallback={this.handleFormSubmit}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  queues: state.app.queues,
  queueId: state.app.queueId,
  till: state.app.till,
})
const mapDispatchToProps = {
  setCurrentConfig,
}
export default connect(mapStateToProps, mapDispatchToProps)(Config)
