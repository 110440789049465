const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-filaunicacasse-itlm-ping-zzgcukhznq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://filaunica-api-test.cloud.leroymerlin.it/api/v1',
  },
  test: {
    LOGIN_URL: `https://test-lmit-filaunicacasse-itlm-ping-zzgcukhznq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://filaunica-api-test.cloud.leroymerlin.it/api/v1',
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-filaunicacasse-itlm-ping-zzgcukhznq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://filaunica-api.cloud.leroymerlin.it/api/v1',
  },
}

export default envConfig[env]
