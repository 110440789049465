import store from 'Redux/store'
import { SET_QUEUES, SET_CURRENT_CONFIG, NEW_CALL } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const setQueues = queues => {
  return { type: SET_QUEUES, payload: queues }
}

export const setCurrentConfig = config => {
  return { type: SET_CURRENT_CONFIG, payload: config }
}

export const newCall = () => dispatch => {
  const { email, storeId } = store.getState().user
  const { queueId, till } = store.getState().app
  const data = { email, storeId, queueId, till }
  return api.till
    .newCall(data)
    .then(res => dispatch({ type: NEW_CALL }))
    .catch(err => handleServerError(err))
}
