import React from 'react'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
const CustomMenu = props => {
  const location = useLocation()
  return (
    <Menu
      mode="horizontal"
      selectedKeys={[location.pathname]}
      style={{ lineHeight: '69px' }}>
      <Menu.Item key="/call">
        <Link to="/call">Chiamate</Link>
      </Menu.Item>
      <Menu.Item key="/">
        <Link to="/">Configurazioni</Link>
      </Menu.Item>
    </Menu>
  )
}
export default CustomMenu
