import { VALIDATE_TOKEN } from '../action-types'
import { setQueues } from './app-actions'
import axiosConfig from 'Common/axiosConfig'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const validateToken = token => dispatch => {
  return api.user
    .authenticate(token)
    .then(res => {
      const user = { ...res.data.user, token }
      axiosConfig.defaults.headers.Authorization = `Bearer ${token}`
      dispatch(setQueues(res.data.config))
      return dispatch({ type: VALIDATE_TOKEN, payload: user })
    })
    .catch(err => handleServerError(err))
}
