import React from 'react'
import PropTypes from 'prop-types'
import { PageTitle } from 'Common/styled'
const StoreLabel = props => {
  const { storeId, storeType, queue, till } = props
  return (
    <PageTitle level={4}>
      {storeType === 'showroom'
        ? `Showroom ${storeId - 400}`
        : `Negozio ${storeId}`}
      {queue ? <><br />{queue}</> : ''}
      {till ? <><br />Cassa {till}</> : ''}
    </PageTitle>
  )
}
StoreLabel.propTypes = {
  storeId: PropTypes.number,
  storeType: PropTypes.string,
  till: PropTypes.number,
}
export default StoreLabel
