import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { newCall } from 'Redux/actions/app-actions'

import StoreLabel from 'Components/StoreLabel'
import { CallButton } from './styled'

class Call extends Component {
  static propTypes = {
    newCall: PropTypes.func,
    queueId: PropTypes.string,
    till: PropTypes.number,
    user: PropTypes.object,
  }

  render() {
    const { user, queues, queueId, till, newCall } = this.props
    return !(queueId && till) ? (
      <Redirect to="/" />
    ) : (
      <>
        <StoreLabel
          storeId={user.storeId}
          storeType={user.storeType}
          queue={queues.find(q => q.queueId === queueId).queueName}
          till={till}
        />
        <CallButton
          icon="plus"
          shape="circle"
          ghost
          type="primary"
          onClick={newCall}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  queues: state.app.queues,
  queueId: state.app.queueId,
  till: state.app.till,
})
const mapDispatchToProps = {
  newCall,
}
export default connect(mapStateToProps, mapDispatchToProps)(Call)
