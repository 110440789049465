import styled from 'styled-components'
import { Layout } from 'antd'

export const StyledFooter = styled(Layout.Footer)`
  color: darkgrey;
  text-align: center;
  padding: 0;
  height: 32px;
  & > * {
    &::after {
      content: '•';
      padding: 0 8px;
      font-weight: bold;
    }
    &:last-child::after {
      content: '';
    }
  }
`
